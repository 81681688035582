import axios from "axios";
import { getAuthorization } from "@/config/token.config";

export function tryModifyContrat(contrat_id, contrat, keycloak, app) {
    return new Promise((resolve, reject) => {
        if (keycloak.hasRealmRole(app + "_admin")) {
            resolve(createModifyRequest(contrat_id, contrat, app));
        } else {
            reject("Vous n'avez pas le droit d'accéder à cette ressource.");
        }
    });
}

async function createModifyRequest(contrat_id, contrat, app) {
    const header = await getAuthorization();
    return axios.put(`${process.env.VUE_APP_GATEWAY_URL}/tools/${app}/contrats/${contrat_id}`, contrat, header);
}
