<template>
  <div>
    <ul>
      <li v-for="app in apps" :key="app">
        <router-link :to="/admin/ + app">{{ app }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "admin",
  data () {
    return {
      apps: this.$store.state.adminOf
    }
  }
}
</script>

<style scoped>

</style>
