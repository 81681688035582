<template>
  <b-button
            label="Ajouter un contrat"
          type="is-primary"
          icon-left="plus"
          @click="createQuota()"/>
</template>

<script>
export default {
  name: "boutonCreateQuota",
  methods: {
    createQuota() {
      this.$router.push("/admin/" + this.$route.params.app + "/users/" + this.$route.params.id_user + "/contrat/new");
    }
  }
}
</script>

<style scoped>

</style>
