
<template>
  <b-navbar type="is-dark">
    <template #brand>
        <img alt="Logo AGT" id="logo_home" src="../assets/logo_agt.svg" style="background-color:white; padding:3px; margin:5px; height: 50px; border-radius:2px" />

    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/' }"> Accueil </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/mon_compte' }">
          Ma consommation
    </b-navbar-item>
      <!--
      <b-navbar-item href="#"> Liste des utilisateurs </b-navbar-item>
      <b-navbar-dropdown label="Info">
        <b-navbar-item href="#"> About </b-navbar-item>
        <b-navbar-item href="#"> Contact </b-navbar-item>
      </b-navbar-dropdown>-->
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <div v-if="!keycloak.authenticated">
            <a class="button is-primary">
              <strong>Se connecter</strong>
            </a>
          </div>

          <a class="button is-danger" @click="keycloak.logoutFn">Déconnexion</a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import router from "@/router";

export default {
  name: "Header",
  data: function () {
    return {
      user: null,
      keycloak: null,
    };
  },

  created: function () {
    // TODO : message d'erreur => router.app.$keycloak undefined ( hypothèse Keycloak n'a pas fini de se charger)
    this.user = router.app.$keycloak.tokenParsed;
    this.keycloak = router.app.$keycloak;
  },
};
</script>
