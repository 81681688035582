import axios from "axios";
import { getAuthorization } from "@/config/token.config";

export function tryDeleteQuota(contrat_id, usage, keycloak, app) {
    return new Promise((resolve, reject) => {
        if (keycloak.hasRealmRole(app + "_admin")) {
            resolve(createDeleteRequest(contrat_id, usage, app));
        } else {
            reject("Vous n'avez pas le droit d'accéder à cette ressource.");
        }
    });
}

async function createDeleteRequest(contrat_id, usage, app) {
    const header = await getAuthorization();
    return axios.delete(`${process.env.VUE_APP_GATEWAY_URL}/tools/${app}/contrats/${contrat_id}/quotas/${usage}`, header);
}


