<template>
  <div id="app">
    <Header/>
    <h1>Suivi de consommation</h1>
    <img alt="Logo AGT" id="logo_home" src="./assets/logo_agt.svg">
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./components/Header.vue"

require("dotenv").config();
export default {
  name: 'App',
  components: {Header},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app h1{
  font-size: 3rem;
  margin-bottom: 5px;
}

#logo_home {
  height: 100px;
}

table {
  text-align: left;
}

@import "https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css";
</style>
