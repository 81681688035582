<template>
      <div v-if="areAppFetched">
        <div v-for="app in apps" :key="app._id">
            <!--<router-link v-if="hasAccessTo(app.outil)" :to="'/mon_compte/' + app.outil"><p>{{ app.outil }}</p></router-link>-->
            <router-link :to="'/mon_compte/' + app.outil"><p>{{ app.outil }}</p></router-link>
        </div>
      </div>
      <div v-else-if="error !== null">
          <p>Erreur : {{ error }}</p>
      </div>
      <div v-else>
          <p>Pas de contrat en cours pour cet utilisateur</p>
      </div>
</template>

<script>

import axios from "axios";
import { getAuthorization } from "@/config/token.config"
import router from "@/router";

export default {
  name: "my_account",
  data: function () {
    return {
      user: null,
      apps: null,
      error: null,
      areAppFetched: false,
    }
  },

  created: function () {
    this.user = router.app.$keycloak.tokenParsed
    try {
      this.fetchAppForUser();
    } catch (err) {
      console.log(err.message);
    }
  },

  methods: {
    fetchAppForUser: async function () {
      //On crée une varaible header dans laquelle on met notre token 
      const header = await getAuthorization(this.outil);
      let page = this;
      axios.get(`${process.env.VUE_APP_GATEWAY_URL}/users/me/contrats/current`, header).then(value => {
        page.apps = value.data
        // eslint-disable-next-line no-prototype-builtins
        if (page.apps.hasOwnProperty("message")) {
          page.error = value.data.message;
        }
        else if (0 != page.apps.length) {
          page.areAppFetched = true;
        }
      }).catch(reason => {
        console.dir(reason);
      });
    },
    getProgress: function () {
      return (this.contrat.conso * 100) / this.contrat.quota;
    },
    /*hasAccessTo(outilName) {
      return this.$keycloak.hasRealmRole(outilName.toLowerCase() + "_client") //! à changer
    },*/
    getProgressType: function () {
      return this.getProgress() >= 80 ? "is-warning" : "is-success"
    },
    timestampToDate: function (timestamp) {
      let date = new Date(Number(timestamp));
      return date.getDate() +
          "/" + (date.getMonth() + 1) +
          "/" + date.getFullYear() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    }
  }
}
</script>

<style scoped>
div.account {
  min-width: 400px;
  max-width: 650px;
  margin: auto;
}
</style>
