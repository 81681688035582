<template>
<div class="columns is-centered ;">
  <section style="margin-top:2%;width:75%">
                    <div v-for="quota in new_quotas" :key="quota.usage">
                                                <b-field v-bind:key="quota.usage" style="margin-bottom:1%"><b style="margin-right:2%">{{ quota.usage }}</b>
                                                        <b-numberinput v-model="quota.quota">
                                                      </b-numberinput>
                                                      <b-button type="is-danger" icon-right="delete" @click="deleteQuota(quota.usage, contrat_id, quota.conso)"
                                                        style="margin-left:50%">
                                                        Supprimer
                                                      </b-button>
                                                      </b-field>
                                                        <div class="notification" style="margin-bottom:5%; width: 12%;">
                                                                  <b>Conso : {{ quota.conso }}</b>
                                                                  </div>
                            </div>
                              <div>
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                  <template #trigger="props">
                        <b-button label="Nouveaux quotas" 
                        type="is-primary" 
                        aria-controls="contentIdForA11y1"
                          :aria-expanded="props.open"
                          :disabled="quotasPossible.length === 0" />
                    </template>
                    <div class="notification">
                      <div class="content">
                        <div v-for="quota in quotasPossible" :key="quota.usage">
                        <b-field v-bind:key="quota.usage" style="margin-bottom:6%"> <b>{{ quota.usage }}</b>
                        <b-numberinput v-model="quota.quota">
                        </b-numberinput>
                      </b-field>
                    </div>
                    <b-button label="Ajouter les quotas" type="is-primary" icon-left="save" @click="saveContrat();" :disabled="quotasPossible.length === 0"  />
                  </div>
                </div>
              </b-collapse>
            </div>
            <div style=margin-left:20px>
              <b-collapse :open="false" aria-id="contentIdForA11y1">
                <p>Ok</p>
              </b-collapse>
            </div>
                  <b-field label="Période du contrat" style="margin-top:10%">
                  <b-datepicker v-model="dateDebut"
                                editable style="margin-left:auto">
                  </b-datepicker>
                  <b-datepicker v-model="dateFin"
                                editable style="margin-right:auto">
                  </b-datepicker>
                </b-field>

                <b-button
                    label="Enregister les modifications"
                  type="is-primary"
                :disabled="!this.dateDebut || !this.dateFin || !this.new_quotas || this.dateDebut > this.dateFin"
                        @click="saveContrat()"/>
                </section>
              </div>
</template>

<script>
import { tryModifyContrat } from "@/controller/contrats/tryModifyContrat";
import { tryDeleteQuota } from "@/controller/contrats/tryDeleteQuota";
import { tryGetOutil } from "@/controller/outils/tryGetOutil";

export default {
  name: "modifyQuota",
  props: {
    Quota: null,
  },
  data: function () {
    return {
      contrat_id: null,
      new_quotas: [],
      dateDebut: new Date(),
      dateFin: new Date(),
      quotasPossible: [],
      outil: null,
      usagesManquants: [],
    }
  },
  async created() {
    this.Quotas = this.$route.params.Quota;
    this.new_quotas = this.Quota.quotas;
    const tool = this.$route.params.app
    this.outil = await this.getOutil(tool);
    this.usagesManquants = this.getUsagesAvailable();
    this.usagesManquants.forEach(usage => {
      this.quotasPossible.push({ usage: usage, quota: null });
    })

    this.dateDebut = new Date(Number(this.Quota.debut))
    this.dateFin = new Date(Number(this.Quota.fin));
    this.contrat_id = this.Quota._id
  },

  methods: {
    saveContrat() {
      if ((!this.dateDebut || !this.dateFin) && !this.contrat) {
        return;
      }
      let finChange = (this.dateFin.setHours(23, 59, 59));
      let debutChange = this.dateDebut.setHours(0, 0, 0);
      let contrat_id = this.Quota._id;
      // On ajoute notre quota possible dans nos quotas si on a renseigné un quota
      this.quotasPossible.forEach((quota, index) => {
        if (quota.quota !== null) {
          this.new_quotas.push(quota);
          this.quotasPossible.splice(index, 1);
        }
      })

      let contrat = {
        debut: debutChange,
        fin: finChange,
        quotas: this.new_quotas
      };
      tryModifyContrat(contrat_id, contrat, this.$keycloak, this.$route.params.app)
        .then(value => {
          if (value.data.operation_succeed === true) {
            this.$buefy.toast.open("Quota modifié.");
            this.usagesManquants = this.getUsagesAvailable();
            this.usagesManquants.forEach(usage => {
              if (!this.quotasPossible.find(quota => quota.usage === usage)) {
                this.quotasPossible.push({ usage: usage, quota: null });
              }
            })
            this.$router.push("/admin/" + this.$route.params.app + "/users/" + this.$route.params.id);
          } else {
            this.$buefy.toast.open(value.data.message);
          }
        })
        .catch(reason => {
          this.$buefy.toast.open(reason);
        })
    },
    deleteQuota(usage, contrat_id, conso) {
      this.$buefy.dialog.confirm({
        message: "Le quota de l'usage " + usage + " (ayant une consommation de " + conso + ") sera définitivement supprimé. Voulez-vous continuer ?",
        onConfirm: () => {
          tryDeleteQuota(contrat_id, usage, this.$keycloak, this.$route.params.app)
            .then(value => {
              if (value.data.operation_succeed === true) {
                this.$buefy.toast.open("quota supprimé !");
                this.$router.push("/admin/" + this.$route.params.app + "/users/" + this.$route.params.id);
              } else {
                this.$buefy.toast.open("Une erreur est survenue");
              }
            })
            .catch(reason => {
              this.$buefy.toast.open(reason);
            })
          // On ajoute notre quota possible dans nos quotas si on a renseigné un quota
          this.quotasPossible.push({ usage: usage, quota: null });
          this.new_quotas.splice(this.new_quotas.findIndex(quota => quota.usage === usage), 1);
                }
            });

    },
        async getOutil(tool) {
      const outil = await tryGetOutil(tool, this.$keycloak, this.$route.params.app)
            return outil
        },
        getUsagesAvailable() {
            let usagesPossibles = this.outil.data[0].usages;
            let usagesActuels = this.new_quotas.map(quota => quota.usage);
            let usagesManquant = usagesPossibles.filter(usage => usagesActuels.indexOf(usage) === -1);
      return usagesManquant;
    }
  }
}
</script>

<style scoped>

</style>
