import router from "@/router";

// permet de rentrer des paramètres que l'on rajoutera à nos requêtes axios
function getAuthorization(outil) {
    const configScenario = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + router.app.$keycloak.token,
            outil: outil,
        },
    };
    return configScenario;
}
export { getAuthorization };


