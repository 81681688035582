<template>
  <div>
    <div v-if="contrat">
      <h1>Gestion de l'utilisateur {{ user.email }}</h1>
      <div class="info_contrat">
        <div v-if="areContratsFetched && contrat.length > 0" class="panel-block">
          <quotas :quotas="contrat" @updatedQuota="handleUpdatedQuota"></quotas>
        </div>         
        <div v-else class="panel-block" style="margin-left: auto;margin-right: auto;width: 35em">
          <div>  
            <p>Aucun contrat disponible pour cet utilisateur.</p> 
          </div>
          <div style="margin-left: auto;margin-right: auto">
            <boutonCreateQuota></boutonCreateQuota>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="error">
        <p>Erreur : {{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Quotas from "@/components/admin/users/simeos/quotas";
import boutonCreateQuota from "@/components/admin/users/simeos/boutonCreateQuota";
import { getAuthorization } from "@/config/token.config"


export default {
  name: "admin_outil_user",
  components: { Quotas, boutonCreateQuota },
  data() {
    return {
      app: this.$route.params.app,
      user: null,
      contrat: null,
      areContratsFetched: false,
      error: null,
      quotas: null
    }
  },
  beforeMount: function () {
    try {
      this.user = this.getUserInfos();
    } catch (err) {
      console.log(err.message);
    }
  },
  mounted() {
    this.fetchQuotas();
  },
  methods: {
    fetchQuotas: async function () {
      //On crée une varaible header dans laquelle on met notre token 
      const header = await getAuthorization();

      let page = this;
      axios.get(`${process.env.VUE_APP_GATEWAY_URL}/users/${page.$route.params.id_user}/contrats`, header).then(value => {
        // eslint-disable-next-line no-prototype-builtins
        if (value?.data?.message) {
          page.error = value.data.message;
        }
        else {
          page.contrat = value?.data?.filter(c => c.outil === this.$route.params.app);
          page.contrat.sort((a, b) => { //sort dans l'ordre chronologique croissant
            return a.debut - b.debut;
          });
          const copieContrat = page.contrat;
          const copieContratNouveauFormat = copieContrat
          copieContratNouveauFormat.forEach((q, i) => {
            q.id = i + 1
            q.date_debut = this.formatDate(q.debut);
            q.date_fin = this.formatDate(q.fin);
            q.usages = []
            q.quotas.forEach((quota) => {
              q.usages.push(quota.usage);
            });
          });
          page.contrat = copieContratNouveauFormat;
          page.contrat.forEach((c) => {
            c.usages = c.usages.join(", ");
          });
          page.areContratsFetched = true;
        }
      }).catch(reason => {
        console.dir(reason);
      });
    },
    handleUpdatedQuota() {
      this.fetchQuotas();
    },
    async getUserInfos() {
      //On crée une varaible header dans laquelle on met notre token 
      const header = await getAuthorization();
      let page = this;
      let user = axios.get(`${process.env.VUE_APP_GATEWAY_URL}/users/${page.$route.params.id_user}`, header).then(value => {
        page.user = value.data;
        return user.data;
      }).catch(reason => {
        console.dir(reason);
      });
    },
    formatDate: function (timestamp) {
      let date = new Date(Number(timestamp));
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let year = date.getFullYear();
      let hours = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
    modifyContrat() {
      this.$router.push({ name: "modifySimeosContrat", params: { Contrat: this.contrat } });
    }
  }
}
</script>

<style scoped></style>
