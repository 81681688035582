<template>
  <div class="account">
    <p>Utilisateur : {{ user.name }}</p>
          <p>ID : {{ user.sub }}</p>
        <div v-if="isActualContratFetched && error === null">
          <p>Outil : {{ contrat.outil }}</p>
          <p>Votre contrat actuel s'étend du <b>{{ timestampToDate(contrat.debut) }}</b> au <b>{{
            timestampToDate(contrat.fin) }}</b></p>
          <div v-for="quotaValue in quotas" v-bind:key="quotaValue.usage" style="margin-top:5%">
            <p>Consommation de l'usage {{ quotaValue.usage }} : {{ quotaValue.conso }} / {{ quotaValue.quota }}:<b-progress
                format="percent" :type="getProgressType()" :value="getProgress(quotaValue.quota, quotaValue.conso)"
                show-value></b-progress>
            </p>
      </div>
    </div>
    <div v-else-if="error !== null">
      <p>Erreur : {{ error }}</p>
    </div>
    <div v-else>
      <p>Pas de contrat en cours pour cet utilisateur</p>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { getAuthorization } from "@/config/token.config"

export default {
  name: "contrat_outil",
  data: function () {
    return {
      user: null,
      contrat: null,
      error: null,
      isActualContratFetched: false,
    }
  },

  created: function () {
    this.user = router.app.$keycloak.tokenParsed
    try {
      this.fetchContratForUser();
    } catch (err) {
      console.log(err.message);
    }
  },

  methods: {
    fetchContratForUser: async function () {
      let page = this;
      const header = await getAuthorization();
      axios.get(`${process.env.VUE_APP_GATEWAY_URL}/users/me/contrats/current`, header).then(value => {
        // eslint-disable-next-line no-prototype-builtins
        if (value.data.hasOwnProperty("message")) {
          page.error = value.data.message;
        }
        else {
          page.contrat = value?.data?.find(contrat => contrat.outil === this.$route.params.app);   //TODO changer les vue par outil en classe générique
          page.quotas = page?.contrat?.quotas
          if (page?.contrat) {
            page.isActualContratFetched = true;
          }
        }
      }).catch(reason => {
        console.dir(reason);
      });
    },
    getProgress: function (quota, conso) {
      return (conso * 100) / quota;
    },
    getProgressType: function () {
      return this.getProgress() >= 80 ? "is-warning" : "is-success"
    },
    timestampToDate: function (timestamp) {
      let date = new Date(Number(timestamp));
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let year = date.getFullYear();
      let hours = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
  }
}
</script>

<style scoped>
div.account {
  min-width: 400px;
  max-width: 650px;
  margin: auto;
}
</style>
