<template>
  <div>
    <h1>Liste des utilisateurs de {{ app.toLowerCase() }}</h1>
    <div v-if="areUsersFetched && users.length > 0">
      <b-field>
        <b-button
            :disabled="!selected_user"
            icon-left="close"
            label="Désélectionner"
            type="is-danger"
            @click="selected_user = null"/>
      </b-field>
      <b-table
          :columns="columns"
          :current-page.sync="currentPage"
          :data="users"
          :paginated=true
          :pagination-rounded=true
          :per-page="perPage"
          :selected.sync="selected_user"
          :sort-icon="sortIcon"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="firstName"
          focusable
          pagination-position="bottom"
          sort-icon-size="is_small"
          trackBy="id">
      </b-table>
      <consult-user :active="this.selected_user" :app="app" v-bind:user="this.selected_user"></consult-user>
    </div>
    <br/>
    <!--<b-button
        :active="true"
        icon-left="plus"
        type="is-primary"
        @click="createContrat()">
      Ajouter un utilisateur
    </b-button>-->
  </div>
</template>

<script>
import axios from "axios";
import ConsultUser from "@/components/admin/consultUser";
import {getAuthorization } from "../config/token.config"


export default {
  name: "app_list_user",
  components: {
    ConsultUser
  },
  data() {
    return {
      columns: [
        {
          label: "Nom",
          field: "lastName",
          searchable: true,
          sortable: true
        }, {
          label: "Prénom",
          field: "firstName",
          searchable: true,
          sortable: true
        }, {
          label: "Mail",
          field: "username",
          searchable: true,
          sortable: true
        }],
      perPage: 20,
      currentPage: 1,
      sortIcon: 'arrow-up',
      selected_user: null,

      users: [],
      areUsersFetched: false,
      app: this.$route.params.app || "",
      u: this.$keycloak
    }
  },
  /*mounted() {
    this.$store.commit("setAdminComp", this.app)
  },*/
  created: function () {
    try {
      this.fetchUsers();
    } catch (err) {
      console.log(err.message);
    }
  },
  methods: {
    fetchUsers: async function () {

            
      //On crée une varaible header dans laquelle on met notre token 
      const header = await getAuthorization();
      

      let page = this;
      axios.get(`${process.env.VUE_APP_GATEWAY_URL}/tools/${page.app}/users`, header).then(value => {
        page.users = value.data;
        page.areUsersFetched = true;
      }).catch(reason => {
        console.dir(reason);
      });
    },
    // createContrat() {
    //   this.$router.push({name: "createContrat", app: this.app})
    // }
  }
}
</script>

<style scoped>

</style>
