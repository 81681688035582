<template>
  <div>
    <h1>Liste des utilisateurs</h1>
    <div v-if="areUsersFetched && users.length > 0">
      <b-field>
        <b-button
            label="Désélectionner"
            type="is-danger"
            icon-left="close"
            :disabled="!selected_user"
            @click="selected_user = null"/>
      </b-field>
      <b-table
          :columns="columns"
          :data="users"
          trackBy="id"
          :paginated=true
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-rounded=true
          :sort-icon="sortIcon"
          sort-icon-size="is_small"
          pagination-position="bottom"
          default-sort="firstName"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :selected.sync="selected_user"
          focusable>
      </b-table>

      <consult-user :active="this.selected_user" v-bind:user="this.selected_user"></consult-user>

    </div>
    <h2 v-else>
      Pas d'utilisateurs
    </h2>
  </div>
</template>
<script>
import axios from "axios";
import ConsultUser from "@/components/admin/consultUser";
import {getAuthorization } from "@/config/token.config"


export default {
  name: "userlist",
  components: {
    ConsultUser
  },
  data: function () {
    return {
      columns: [
        {
          label: "Nom",
          field: "lastName",
          searchable: true,
          sortable: true
        }, {
          label: "Prénom",
          field: "firstName",
          searchable: true,
          sortable: true
        }, {
          label: "Mail",
          field: "username",
          searchable: true,
          sortable: true
        }],
      users: [],
      areUsersFetched: false,
      perPage: 20,
      currentPage: 1,
      sortIcon: 'arrow-up',
      selected_user: null
    };
  },

  created: function () {
    try {
      this.fetchUsers();
    } catch (err) {
      console.log(err.message);
    }
  },

  methods: {
    fetchUsers: async function () {
      //On crée une variable header dans laquelle on met notre token 
      const header = await getAuthorization();
      

      let page = this;
      axios.get(`${process.env.VUE_APP_GATEWAY_URL}/users`, header).then(value => {
        page.users = value.data;
        page.areUsersFetched = true;
      }).catch(reason => {
        console.dir(reason);
      });
    }
  }
}
</script>

<style scoped>

</style>
