import Vue from "vue";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import { KeycloakConfig } from "./config/keycloak.config";
import { router } from "./router";
import { store } from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import App from "@/App";

Vue.use(Buefy);

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "check-sso",
    loginRequired: true,
  },
  config: KeycloakConfig,
  //logout: { redirectUri: 'https://alsacreations.com' },
  //logout: process.env.VUE_APP_SERVER_URL,
  onReady: () => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  },
});
