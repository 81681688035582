<template>
  <div>
    <div v-if="!keycloak.authenticated">
      <router-link v-bind:to="keycloak.login()">Connexion</router-link><br/>
    </div>
    <div v-else>
      Bonjour {{user.name}}
    </div>
        <router-link to="/mon_compte">Mon compte</router-link><br /> 
      <router-link to="/admin" v-if="this.$store.state.isAdmin">Espace Administration</router-link><br /><br />
    <b-button
        label="Déconnexion"
        type="is-danger"
        icon-left="logout"
        @click="this.$keycloak.logoutFn"/>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "home",
  data: function () {
    return {
      user: null,
      keycloak: null
    }
  },

  created : function () {
    this.user = router.app.$keycloak.tokenParsed;
    this.keycloak = router.app.$keycloak;
  },

}
</script>

<style scoped>

</style>
