<template>
  <b-field>
    <b-button
        v-bind:label="this.getBtnLabel(user)"
        type="is-primary"
        icon-left="arrow-right"
        :disabled="!active"
        @click="goToUserPage(user, app)"/>
  </b-field>
</template>

<script>
export default {
  name: "consultUser",
  props: ['user', "active", "onClick", "app"],
  methods: {
    getBtnLabel: function (user) {
      if (!user) {
        return "Sélectionnez un utilisateur";
      }
      return `Consulter l'utilisateur : ${user.firstName} ${user.lastName}`;
    },
    goToUserPage: function (user, app) {
      if (user) {
        this.$router.push("/admin/" + app + "/users/" + user.id)
      }
    }
  }
}
</script>

<style scoped>

</style>
