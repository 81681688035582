<template>
<div class="columns is-centered ;">
  <section style="margin-top:2%;width: 25%;">
            <div v-for="quota in quotas" :key="quota.usage" style="width:70%;margin:auto">
                <b-field v-bind:key="quota.usage"> {{ quota.usage }}
              <b-numberinput v-model="quota.quota">
              </b-numberinput>
            </b-field>
          </div>

          <b-collapse :open="false" style="margin-top:10%" aria-id="contentIdForA11y1">
            <template #trigger="props">
              <b-button label="Modifier maximum requêtes" type="is-primary" aria-controls="contentIdForA11y1"
                :aria-expanded="props.open" />
              </template>
                        <div class="notification" v-bind="outil">
                          <p>
                          <b>Attention</b> : Des valeurs par défauts sont déjà affectées.     
                          <br>Si vous souhaitez affecter vos propres valeurs, remplissez les champs ci-dessous
                          </p>

                          <b-field style="margin-top:3%" label="Maximum de requête par seconde :">
                              <b-numberinput v-model="max_per_second" :placeholder="null">
                                </b-numberinput>
                            </b-field>
                                        <p>Valeur par défaut : <b>{{ max_per_second_default }}</b></p><br>
                                <b-field label="Maximum de requête par minute :" >
                                  <b-numberinput v-model="max_per_minute" :placeholder=null>
                              </b-numberinput>
                            </b-field>
                            <p>Valeur par défaut : <b>{{ max_per_minute_default }}</b></p><br>
                            <b-field label="Maximum de requête par heure :" >
                              <b-numberinput v-model="max_per_hour" :placeholder="null">
                        </b-numberinput>
                        </b-field>
                        <p>Valeur par défaut : <b>{{ max_per_hour_default }}</b></p>
                  
                  </div>
          </b-collapse>
                <b-field label="Période du quota" style="text:center;margin-top:10%" >

            <b-datepicker v-model="dateDebut"
                          editable style="margin-left:auto">
            </b-datepicker>
            <b-datepicker v-model="dateFin"
                          editable style="margin-right:auto">
            </b-datepicker>

          </b-field>
          <b-button
              label="Ajouter un contrat"
              type="is-primary"
              icon-left="save"
              :disabled="!this.dateDebut || !this.dateFin || this.dateDebut > this.dateFin"
              @click="saveQuota()"/>
        </section>
      </div>
</template>

<script>
import { tryCreateContrat } from "@/controller/contrats/tryCreateContrat";
import { tryGetOutil } from "@/controller/outils/tryGetOutil";


export default {
  name: "createQuota",
  data: function () {
    return {
      dateDebut: new Date(),
      dateFin: new Date(),
      outil: {},
      usage: null,
      quotas: null,
      max_per_hour: null,
      max_per_minute: null,
      max_per_second: null,
      max_per_hour_default: null,
      max_per_minute_default: null,
      max_per_second_default: null,
    }
    
  },
     beforeMount: async function () {
    try {
      this.tool = this.$route.params.app;
      this.outil = await this.getOutil(this.tool);
      this.quotas = [];
      this.usages = this.outil.data[0].usages;
    } catch (err) {
      console.log(err.message);
    }
    this.usages.forEach(usage => {
      this.quotas.push({ usage: usage, quota: null });
    });
    this.max_per_hour_default = this.outil.data[0].max_per_hour;
    this.max_per_minute_default = this.outil.data[0].max_per_minute;
    this.max_per_second_default = this.outil.data[0].max_per_second;
  },

  //TODO : mettre dans le bouton "Ajouter un contrat" une condition pour que le bouton soit disabled si aucun quota n'est renseigné
  methods: {

    saveQuota() {
      if ((!this.dateDebut || !this.dateFin) && !this.quota) {
        return;
      }
      let finChange = (this.dateFin.setHours(23, 59, 59));
      let debutChange = this.dateDebut.setHours(0, 0, 0);
      let quotasNonVide = this.quotas.filter(quota => quota.quota !== null);
      let contrat = {
        user_id: this.$route.params.id,
        debut: debutChange,
        fin: finChange,
        quotas: quotasNonVide,
        max_per_second: this.max_per_second,
        max_per_minute: this.max_per_minute,
        tool: this.tool,
        max_per_hour: this.max_per_hour
      };



      tryCreateContrat(contrat, this.$keycloak, this.$route.params.app)
        .then(value => {
          if (value.data.operation_succeed === true) {
              this.$buefy.toast.open("Contrat créé.");
              console.log("Contrat créé")
            this.$router.push("/admin/" + this.$route.params.app + "/users/" + this.$route.params.id);
            } else {
              this.$buefy.toast.open(value.data.message);
            }
          })
          .catch(reason => {
            this.$buefy.toast.open(reason);
          })
    },
    async getOutil(tool) {
      const outil = await tryGetOutil(tool, this.$keycloak, this.$route.params.app)
      
      return outil
    }
  }
}
</script>

<style scoped>

</style>
