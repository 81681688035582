<template>
  <div>
    <boutonCreateQuota></boutonCreateQuota>
    <b-button
        v-bind:label="getModBtnLabel(this.quota)"
        type="is-danger is-light"
        icon-left="edit"
        :disabled="!this.quota"
        @click="modifyQuota()"/>
    <b-button
        v-bind:label="getDelBtnLabel(this.quota)"
        type="is-danger is-light"
        icon-left="delete"
        :disabled="!this.quota"
        @click="deleteQuota()"/>
  </div>
</template>

<script>
import {tryDeleteContrat} from "@/controller/contrats/tryDeleteContrat"
import boutonCreateQuota from "@/components/admin/users/simeos/boutonCreateQuota";

export default {
  name: "boutonsQuota",
  components: {
    boutonCreateQuota
  },
  props: {
    quota: {
      type: Object,
      default: null
    },
  },

  methods: {
    getModBtnLabel: function (quota) {
      if (!quota) {
        return "Sélectionnez un contrat";
      }
      return `Modifier le contrat sélectionné`;
    },
    getDelBtnLabel: function (quota) {
      if (!quota) {
        return "Sélectionnez un contrat";
      }
      return `Supprimer le contrat sélectionné`;
    },
    modifyQuota() {
      this.$router.push({ name: "modifyQuota", params: { Quota: this.quota, id: this.quota.user_id, outil: this.quota.outil } });
    },
    deleteQuota() {
      this.$buefy.dialog.confirm({
        message: 'Le contrat sera définitivement supprimé. Voulez-vous continuer ?',
        onConfirm: () => {
          tryDeleteContrat(this.quota, this.$keycloak, this.$route.params.app)
            .then(value => {
              if (value.data.operation_succeed === true) {
                  this.$buefy.toast.open("Contrat supprimé !");
                  this.$emit('updatedQuota');
                } else {
                  this.$buefy.toast.open("Une erreur est survenue");
                }
              })
              .catch(reason => {
                this.$buefy.toast.open(reason);
              })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
