<template>
  <div>
    <div v-if="$store.state.adminComp">
      <component v-bind:is="this.$store.state.adminComp"></component>
<!--      <admin_simeosAPI_user></admin_simeosAPI_user>-->
    </div>
    <div v-else>
      Erreur lors du chargement de la page.
    </div>
  </div>
</template>

<script>
import adminOutilApiUser from "@/components/admin/users/simeos/admin_outil_user";
//import adminSimeosUiUser from "@/components/admin/users/simeos-ui/admin_simeosUI_user";

export default {
  name: "admin_app_user",
  props: ["app_admin_component"],
  components: {
    "admin_outil_user": adminOutilApiUser,
    //"admin_simeosUI_user": adminSimeosUiUser
  },
  /*beforeCreate() {
    this.$store.commit("setAdminComp", this.$route.params.app)
  }*/
}
</script>

<style scoped>

</style>
