<template>
  <div id="app" class="container">

  <section>
            <b-table :data="quotas" ref="table" paginated per-page="5"  detailed detail-key="id"   
              aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page" :selected.sync="selected_quota">

              <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="usages" label="Usages" sortable v-slot="props">
                  <!-- <template v-if="showDetailIcon">
          {{ props.row.usages}}
        </template> -->
                  <template >
          <a @click="props.toggleDetails(props.row)">
            {{ props.row.usages}}
          </a>
        </template>
      </b-table-column>

      <b-table-column field="date_debut" label="Date Début" sortable centered v-slot="props">
          {{ props.row.date_debut }}
      </b-table-column>

      <b-table-column field="date_fin" label="Date Fin" sortable centered v-slot="props">
          {{ props.row.date_fin}}
      </b-table-column>

      <template #detail="props">
        <article class="media">
          <div  in  class="media-content">
            <div class="content">
                <li v-for="quota in props.row.quotas" :key="quota.usage">
                <p><strong>Usage : {{ quota.usage }}</strong></p>
                <small>Quota : {{ quota.quota }} || Conso : {{quota.conso }}</small>
              </li>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  <boutons-quota v-bind:quota="this.selected_quota" @updatedQuota="handleUpdatedQuota"></boutons-quota>
  </section>
</div>
</template>

<script>

import boutonsQuota from "@/components/admin/users/simeos/boutonsQuota";

export default {
  name: "quotas",
  components: {
    boutonsQuota
  },
  props: {
    quotas: Array
  },

  data: function () {
    return {
      perPage: 20,
      currentPage: 1,
      sortIcon: 'arrow-up',
      selected_quota: null,
    }
  },
  methods: {
    handleUpdatedQuota() {
      this.$emit("updatedQuota")
    }
  }
}
</script>

<style scoped>

</style>
