import Vue from "vue";
import VueRouter from "vue-router";
import userlist from "@/components/admin/listeUsers/userlist";
//import users from "@/components/admin/users/users";
import home from "@/pages/home";
import my_account from "@/components/users/my_account";
import createQuota from "@/components/admin/users/simeos/createQuota";
import modifyQuota from "@/components/admin/users/simeos/modifyQuota";
//import contrat_simeos from "./components/users/applications/contrat_simeos";
//import contrat_cges from "./components/users/applications/contrat_cges";
//import contrat_demetra from "./components/users/applications/contrat_demetra";
import contrat_outil from "./components/users/applications/contrat_outil";
//import simeosContrat from "./components/admin/users/contrats/simeosContrat";

import { store } from "./store.js";
import admin from "@/pages/admin";
import app_list_user from "@/pages/app_list_user";
import admin_app_user from "@/pages/admin_app_user";
//import createContrat from "@/pages/createContrat";

Vue.use(VueRouter);

export const router = new VueRouter({
           mode: "history",
           routes: [
               {
                   path: "/",
                   component: home,
               },
               {
                   path: "/mon_compte",
                   component: my_account,
                   meta: {
                       requiresAuth: true,
                       authorizedRoles: [],
                   },
               },
               {
                   path: "/mon_compte/:app",
                   component: contrat_outil,
                   meta: {
                       requiresAuth: true,
                       authorizedRoles: [],
                   },
               },
               {
                   path: "/admin",
                   component: admin,
                   meta: {
                       requiresAuth: true,
                       shouldBeAdmin: true,
                   },
               },
               {
                   path: "/admin/:app",
                   component: app_list_user,
                   meta: {
                       requiresAuth: true,
                       shouldBeAdmin: true,
                       authorizedRoles: ["{app}_admin"],
                   },
               },
               {
                   path: "/admin/:app/users/:id_user",
                   component: admin_app_user,
                   meta: {
                       requiresAuth: true,
                       shouldBeAdmin: true,
                       authorizedRoles: ["{app}_admin"],
                   },
               },
               {
                   path: "/userList",
                   component: userlist,
                   meta: {
                       requiresAuth: true,
                       authorizedRoles: ["admin_simeos"],
                   },
               },
               {
                   path: "/admin/:app/users/:id/contrat/new",
                   component: createQuota,
                   meta: {
                       requiresAuth: true,
                       shouldBeAdmin: true,
                       authorizedRoles: ["{app}_admin"],
                   },
               },
               {
                   path: "/admin/:app/users/:id/contrat/edit",
                   name: "modifyQuota",
                   component: modifyQuota,
                   props: (route) => ({
                       Contrat: Object,
                       ...route.params,
                   }),
                   meta: {
                       requiresAuth: true,
                       shouldBeAdmin: true,
                       authorizedRoles: ["{app}_admin"],
                   },
               },
               /*{
                   path: "/users/:id",
                   component: users,
                   meta: {
                       requiresAuth: true,
                       authorizedRoles: ["admin_simeos"],
                   },
               },
               {
                   path: "/mon_compte/simeos",
                   component: contrat_simeos,
                   meta: {
                       requiresAuth: false, //! à changer
                       authorizedRoles: ["simeos_client"],
                   },
               },

               {
                   path: "/mon_compte/cges-api",
                   component: contrat_cges,
                   meta: {
                       requiresAuth: false, //! à changer
                       authorizedRoles: ["simeos_client"], //! à changer
                   },
               },
               {
                   path: "/mon_compte/simeos-ui",
                   component: contrat_demetra,
                   meta: {
                       requiresAuth: true,
                       authorizedRoles: ["simeos-ui_client"],
                   },
               },
               {
                   path: "/users/:id/simeos",
                   component: simeosContrat,
                   meta: {
                       requiresAuth: true,
                       authorizedRoles: ["admin_simeos"],
                   },
               },
               {
                   path: "/admin/:app/contrats/new",
                   name: "createContrat",
                   component: createContrat,
                   meta: {
                       requiresAuth: true,
                       shouldBeAdmin: true,
                       authorizedRoles: ["{app}_admin"],
                   },
               },*/
           ],
       });

router.beforeEach(async (to, from, next) => {
    if (store.state.isAdmin === false) {
        if (router.app.$keycloak.authenticated) {
            let admin_roles = router.app.$keycloak.realmAccess.roles.filter((r) => r.match(/.+_admin/));
            if (admin_roles.length > 0) {
                store.commit("setAdmin", admin_roles);
            }
        }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // We wait for Keycloak init, then we can call all methods safely
        while (!router.app.$keycloak.ready) {
            await sleep(100);
        }

        if (isAuthenticated()) {
            if (userHasRoles(to) || (to.meta.shouldBeAdmin && userIsAdmin())) {
                return next();
            }
            window.location.replace("/");
        } else {
            /** On redirige quoi qu'il arrive vers la page de connexion Keycloak
             * car loginRequired ne fonctionne que sur l'url racine.
             * Sans ça, après une déconnexion, on reste sur la même page, avec le composant chargé
             **/
            const loginUrl = router.app.$keycloak.createLoginUrl();
            window.location.replace(loginUrl);
        }
    } else {
        next();
    }
});

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function isAuthenticated() {
    return router.app.$keycloak.authenticated;
}

function userHasRoles(to) {
    let isAuthorized = false;
    if (to.matched.some((record) => record.meta.authorizedRoles)) {
        if (isAuthorized) {
            return;
        }
        if (to.meta.authorizedRoles.length === 0) {
            isAuthorized = true;
        } else {
            to.meta.authorizedRoles = replaceParamsInRoles(to.meta.authorizedRoles, to.params);
            to.meta.authorizedRoles.forEach((role) => {
                if (router.app.$keycloak.hasRealmRole(role)) {
                    isAuthorized = true;
                }
            });
        }
    }
    return isAuthorized;
}



function replaceParamsInRoles(rolesList, params) {
  let roleProcessed = [];
  rolesList.forEach((role) => {
    if (role.includes("{") && role.includes("}")) {
      roleProcessed.push(role.replace(/\{(.+)\}/, params[RegExp.$1.toLowerCase()]));
    } else {
      roleProcessed.push(role);
    }
  });
  return roleProcessed;
}

function userIsAdmin() {
    return store.state.isAdmin;
}

export default router;
