import Vue from 'vue';
import 'es6-promise/auto'; // nécessaire pour Vuex
import Vuex from "vuex";
Vue.use(Vuex)

export const store = new Vuex.Store({
           state: {
               targetUser: null,
               isAdmin: false,
               adminOf: [],
               adminComp: "admin_outil_user",
           },
           mutations: {
               changeTargetUser(state, user) {
                   this.state.targetUser = user;
               },
               setAdmin(state, apps) {
                   this.state.isAdmin = true;
                   this.state.adminOf = apps.map((r) => r.split("_")[0]);
               } /*,
               setAdminComp(state, appName) {
                   switch (appName) {
                       case "simeos":
                           this.state.adminComp = "admin_outil_user";
                           break;
                       case "cges-api":
                           this.state.adminComp = "admin_outil_user";
                           break;
                       case "simeos-ui":
                           this.state.adminComp = "admin_simeosUI_user";
                           break;
                       case "pomod":
                           break;
                   }
               },*/,
           },
       });
